/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import LeftArrowIcon from '@material-ui/icons/ArrowBackIos';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import Slider from 'react-slick';
import Link from 'next/link';
import Typography from '@common_typography';
import Thumbor from '@common_imageThumbor';
import { features } from '@config';
import getPath from '@helper_getpath';
import useStyles from './style';

const generateLink = (cat) => {
    const link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;
    if (features.vesMenu.enabled) {
        if (cat.link_type === 'category_link') {
            return ['/[...slug]', link];
        }
        return [link, link];
    }
    return ['/[...slug]', link];
};

const CategorySlider = (props) => {
    const {
        data = [],
        showArrow = true,
        xs = 767,
        sm = 1023,
        md = 1200,
        lg = 1300,
    } = props;
    const styles = useStyles();
    const [slideIndex, setIndex] = useState(0);
    const [count, setCount] = useState(0);
    let sliderRef = React.createRef();

    const activeData = useMemo(() => data[slideIndex], [slideIndex, data]);

    const handleLeftArrow = () => {
        if (slideIndex === 0) {
            sliderRef.slickGoTo(data.length - 1);
        } else {
            sliderRef.slickGoTo(slideIndex - 1);
        }
    };

    const handleRightArrow = () => {
        if (slideIndex === data.length - 1) {
            sliderRef.slickGoTo(0);
        } else {
            sliderRef.slickGoTo(slideIndex + 1);
        }
    };

    const settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        speed: 500,
        autoplaySpeed: 5000,
        rtl: false,
        centerMode: true,
        afterChange: () => setCount(count + 1),
        beforeChange: (current, next) => setIndex(next),
        responsive: [
            {
                breakpoint: lg,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    className: 'slider',
                },
            },
            {
                breakpoint: md,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    className: 'slider',
                },
            },
            {
                breakpoint: sm,
                settings: {
                    slidesToShow: 3,
                    swipeToSlide: true,
                    slidesToScroll: 1,
                    className: 'slider',
                },
            },
            {
                breakpoint: xs,
                settings: {
                    slidesToShow: 1,
                    swipeToSlide: true,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={styles.categoryCaraousel}>
            <div className={styles.sliderContainer}>
                <Slider
                    ref={(slider) => sliderRef = slider}
                    {...settings}
                >
                    {
                        data.map((category, key) => (
                            <Link
                                prefetch={false}
                                href={generateLink(category)[0]}
                                as={generateLink(category)[1]}
                                key={category.id}
                            >
                                <a
                                    className={classNames(
                                        styles.categoryItem,
                                    )}
                                >
                                    <Thumbor
                                        src={category.dropdown_bgimage}
                                        width={222}
                                        height={242}
                                        alt={category.name}
                                        className={styles.categoryImage}
                                        styleContainer={{
                                            minHeight: '140px',
                                        }}
                                    />
                                    <Typography
                                        type="bold"
                                        align="center"
                                        className={
                                            classNames(
                                                styles.categoryTitle,
                                                key === slideIndex ? 'activeSlider' : '',
                                            )
                                        }
                                    >
                                        {category.name}
                                    </Typography>
                                </a>
                            </Link>
                        ))
                    }
                </Slider>

                {
                    showArrow ? (
                        <>
                            <div className={classNames(styles.arrow, styles.leftArrow)} onClick={handleLeftArrow}>
                                <LeftArrowIcon fontSize="inherit" />
                            </div>
                            <div className={classNames(styles.arrow, styles.rightArrow)} onClick={handleRightArrow}>
                                <RightArrowIcon fontSize="inherit" />
                            </div>
                        </>
                    ) : null
                }
            </div>

            {
                activeData ? (
                    <div className={styles.childMenuContainer}>
                        {
                            activeData.children.length > 0 ? (
                                <div className={styles.childMenuItems}>
                                    {
                                        activeData.children.map((dataChild2, idx2) => (
                                            <div key={idx2}>
                                                <Link
                                                    prefetch={false}
                                                    href={generateLink(dataChild2)[0]}
                                                    as={generateLink(dataChild2)[1]}
                                                >
                                                    <a>
                                                        <Typography
                                                            type="bold"
                                                            className={styles.secondLevelTitle}
                                                        >
                                                            {dataChild2.name}
                                                        </Typography>
                                                    </a>
                                                </Link>
                                                {
                                                    dataChild2.children.length > 0 ? (
                                                        <div className={styles.thirdLevelContainer}>
                                                            {
                                                                activeData.children.map((dataChild3, idx3) => (
                                                                    <div key={idx3}>
                                                                        <Link
                                                                            prefetch={false}
                                                                            href={generateLink(dataChild3)[0]}
                                                                            as={generateLink(dataChild3)[1]}
                                                                        >
                                                                            <a className={styles.thirdLink}>
                                                                                <Typography>
                                                                                    {dataChild3.name}
                                                                                </Typography>
                                                                            </a>
                                                                        </Link>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : null
                        }
                    </div>
                ) : null
            }
        </div>
    );
};

export default CategorySlider;
