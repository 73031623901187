import useStyles from './style';
import CategorySlider from './CategorySlider';

const Content = (props) => {
    const {
        homeCategoryData,
    } = props;

    const styles = useStyles();

    return (
        <div className={styles.container}>
            <CategorySlider
                data={homeCategoryData?.children || []}
            />
        </div>
    );
};

export default Content;
