import Layout from '@layout';
import { getHost } from '@helper_config';
import { currencyVar } from '@root/core/services/graphql/cache';
import Cookies from 'js-cookie';
import { getVesMenu } from '@root/src/modules/theme/services/graphql';
import { features } from '@config';
import CategorySkeleton from './components/CategorySkeleton';

const HomeCore = (props) => {
    const {
        Content, pageConfig, storeConfig, ...other
    } = props;

    const { data: dataVesmenu, loading: loadingVesmenu, error } = getVesMenu({
        variables: {
            alias: features.ecatalogHomepage.alias,
        },
    });

    if (typeof window !== 'undefined') {
        const appCurrency = Cookies.get('app_currency');
        currencyVar({
            currency: storeConfig.base_currency_code,
            locale: storeConfig.locale,
            enableRemoveDecimal: storeConfig?.pwa?.remove_decimal_price_enable,
            appCurrency,
        });
    }

    const schemaOrg = [
        {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: `${getHost()}/`,
            logo: `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`,
        },
        {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: `${getHost()}/`,
            potentialAction: [{
                '@type': 'SearchAction',
                target: `${getHost()}/catalogsearch/result?q={search_term_string}`,
                'query-input': 'required name=search_term_string',
            }],
        },
    ];

    const config = {
        title: storeConfig.default_title,
        titleHeader: 'E-CATALOG',
        header: false, // available values: "absolute", "relative", false (default)
        bottomNav: 'home',
        pageType: 'home',
        fullWidth: true,
        schemaOrg,
        ...pageConfig,
    };

    if (loadingVesmenu || error) {
        return (
            <Layout
                {...props}
                {...other}
                pageConfig={config}
            >
                <CategorySkeleton />
            </Layout>
        );
    }

    const homeCategoryData = dataVesmenu?.vesMenu?.items?.[0] || null;

    return (
        <Layout
            {...props}
            {...other}
            pageConfig={config}
        >
            <Content
                storeConfig={storeConfig}
                {...other}
                homeCategoryData={homeCategoryData}
            />
        </Layout>
    );
};

export default HomeCore;
