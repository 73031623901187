import Skeleton from '@common_skeleton';
import React from 'react';
import classNames from 'classnames';
import useStyles from './style';

const CategorySkeleton = () => {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <div className={styles.categoryContainer}>
                <div className={styles.categoryItem}>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="140px"
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                </div>
                <div className={classNames(styles.categoryItem, 'hidden-mobile')}>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="140px"
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                </div>
                <div className={classNames(styles.categoryItem, 'hidden-mobile')}>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="140px"
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                </div>
                <div className={styles.categoryItem}>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                </div>
                <div className={styles.categoryItem}>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                </div>
                <div className={styles.categoryItem}>
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        width="100%"
                        height="27px"
                        className={styles.categoryTitle}
                    />
                </div>
            </div>
        </div>
    );
};

export default CategorySkeleton;
