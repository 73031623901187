import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY } from '@theme_color';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        overflowX: 'hidden',
        padding: 0,
        marginBottom: '120px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '10px',
        },
    },
    categoryContainer: {
        display: 'grid',
        width: '100%',
        gridColumnGap: '20px',
        gridRowGap: '20px',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 1024px )': {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: '20px',
            gridRowGap: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
            gridColumnGap: '10px',
            gridRowGap: '10px',
        },
    },
    categoryItem: {
        width: '100%',
    },
    categoryImage: {
        width: '100%',
    },
    categoryTitle: {
        width: '100%',
        display: 'inline-block',
        // color: '#2E3092',
        color: 'black',
        margin: 0,
        marginTop: '16px',
        fontSize: '16px',
        fontWeight: 600,
        textAlign: 'center',
        fontFamily: 'HartWell',
        '&.activeSlider': {
            fontSize: '20px',
        },
    },
    categoryCaraousel: {
        width: '100%',
        height: '100%',
    },
    sliderContainer: {
        width: '100%',
        margin: '0 auto',
        maxWidth: 'calc(100vw - 354px)',
        position: 'relative',
        '& .slick-slide': {
            padding: '32px',
        },
        '& .slick-center': {
            padding: 0,
        },
        '@media (max-width: 1440px )': {
            maxWidth: 'calc(100vw - 254px)',
        },
        '@media (max-width: 1024px )': {
            maxWidth: 'calc(100vw - 214px)',
        },
        '@media (max-width: 767px )': {
            maxWidth: 'calc(100vw - 70px)',
        },
    },
    arrow: {
        fontSize: '1.5rem',
        backgroundColor: 'rgba(255,255,255,1)',
        position: 'absolute',
        ...Centering,
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 10,
        borderRadius: '50%',
        textAlign: 'center',
        paddingLeft: 10,
        top: 'calc(50% - 15px)',
        width: 40,
        height: 40,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
    },
    leftArrow: {
        left: '10px',
        '@media (max-width: 768px )': {
            left: '-15px',
        },
    },

    rightArrow: {
        right: '10px',
        '@media (max-width: 768px )': {
            right: '-15px',
        },
    },
    childMenuContainer: {
        textAlign: 'center',
        marginTop: '24px',
    },
    childMenuItems: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridRowGap: '20px',
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: '1fr',
        },
    },
    secondLevelTitle: {
        fontSize: '16px',
    },
    thirdLevelContainer: {
        // marginTop: '12px',
    },
    thirdLink: {
        display: 'inline-block',
        marginTop: '6px',
    },
}));

export default useStyles;
